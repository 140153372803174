import {StrictMode} from 'react'
import * as ReactDOM from 'react-dom/client'

import {FirebaseContextProvider} from '@progos/firebase-chat'
import {zelTheme} from '@zel-labs/shared/mui'

import {ZelLabsRouterProvider} from './routes'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {HelmetProvider} from 'react-helmet-async'
import * as Sentry from '@sentry/react'

import '@zel-labs/i18n'
import {AuthenticationContextProvider, UserContextProvider} from '@zel-labs/auth'

const rstring = '2024-09-14-125721'

console.log('rstring', rstring)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const firebaseConfig = {
  apiKey: 'AIzaSyBmiyf-kCnQVFNOIGK4diabtQvvh59HmQo',
  authDomain: 'zel-labs-76294.firebaseapp.com',
  projectId: 'zel-labs-76294',
  storageBucket: 'zel-labs-76294.appspot.com',
  messagingSenderId: '53512655817',
  appId: '1:53512655817:web:6a370a415799ac134e4e18',
  // measurementId: 'G-7Q7BTJ2ZMW'
}

Sentry.init({
  dsn: 'https://a32780aff52e3bc46419b7f22ff49cfd@o4507571563003904.ingest.de.sentry.io/4507571594395728',
  release: rstring,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

root.render(
  <StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={zelTheme}>
        <CssBaseline/>
        <FirebaseContextProvider config={firebaseConfig}>
          <UserContextProvider>
            <AuthenticationContextProvider enabledMethods={['email-password', 'google', 'microsoft']}>
              <ZelLabsRouterProvider/>
            </AuthenticationContextProvider>
          </UserContextProvider>
        </FirebaseContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  </StrictMode>
)



