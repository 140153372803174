import type { QualityFeedback, QualityRating } from '../types'
import type {
  SelectChangeEvent} from '@mui/material'
import {
  FormControl, InputLabel, MenuItem,
  Rating, Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import type { IconContainerProps } from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import type { SyntheticEvent} from 'react'
import { useCallback } from 'react'
import { useLQAFeedbackContext } from './LQAFeedbackContext'


export function QualityRatingControl() {
  const { feedback, setFeedback } = useLQAFeedbackContext()

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const value = e.target.value
      const userRating = (
        (['Low', 'Mid', 'High', 'NoEvidence'].includes(value)) ?
          value:null) as QualityFeedback['userRating']

      setFeedback(feedback => ({
        ...feedback,
        userRating
      }))
    },
    [setFeedback]
  )
  return <FormControl size="small">
    <InputLabel>What is your assessment?</InputLabel>
    <Select
      value={feedback.userRating ?? 'NoEvidence'}
      label="What is your assessment?"
      onChange={handleChange}>
      <MenuItem value="NoEvidence">No evidence in the excerpt to tell</MenuItem>
      <MenuItem value={'Low'}>Low</MenuItem>
      <MenuItem value={'Mid'}>Mid</MenuItem>
      <MenuItem value={'High'}>High</MenuItem>
    </Select>
    {/*<FormHelperText>Please make your decision based on the excerpt</FormHelperText>*/}
  </FormControl>
}

export function QualityRatingButtonGroup() {
  const {feedback, setFeedback} = useLQAFeedbackContext()

  const {userRating} = feedback
  const setRating = useCallback((userRating: QualityRating | null) =>
    setFeedback((feedback) => ({...feedback, userRating}))  ,
    [setFeedback]
  )

  return <Stack direction="row" alignItems="center">
    <Typography variant="overline">{userRating ?? 'Can\'t tell'}</Typography>
    <RatingButtonGroup rating={userRating} setRating={setRating} />
  </Stack>
}

interface QualityRatingControlProps {
  rating: QualityRating | null;
  setRating: (rating: QualityRating | null) => void;
}

function RatingButtonGroup({ rating, setRating }: QualityRatingControlProps) {
  const handleChange = useCallback(
    (event: SyntheticEvent, value: QualityRating | 'Unknown') => {
      if (value==='Unknown') {
        setRating(null)
      } else {
        setRating(value)
      }
    },
    [setRating]
  )
  return <ToggleButtonGroup orientation="horizontal" size="small"
                            value={rating ?? 'Unknown'}
                            onChange={handleChange}
                            color="primary" exclusive={true}>
    <ToggleButton value={'Unknown'}><HelpOutlineIcon /></ToggleButton>
    <ToggleButton value={'Low'}><SentimentVeryDissatisfiedIcon color="error" /></ToggleButton>
    <ToggleButton value={'Mid'}><SentimentSatisfiedIcon color="warning" /></ToggleButton>
    <ToggleButton value={'High'}><SentimentVerySatisfiedIcon color="success" /></ToggleButton>
  </ToggleButtonGroup>
}

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props
  return <span {...other}>{ratings[value]?.icon ?? <HelpOutlineIcon fontSize="large" />}</span>
}


const ratings: Record<number,
  {
    icon: React.ReactElement;
    rating: QualityRating
  }
> = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon fontSize="large" color="error" />,
    rating: 'Low'
  },
  2: {
    icon: <SentimentSatisfiedIcon fontSize="large" color="warning" />,
    rating: 'Mid'
  },
  3: {
    icon: <SentimentVerySatisfiedIcon fontSize="large" color="success" />,
    rating: 'High'
  }
}


const ratingValues: Record<QualityRating, number | null> = {
  'Low': 1,
  'Mid': 2,
  'High': 3
}


const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled
  }
}))

