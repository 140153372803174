import {IconButton, Stack, styled, Typography} from '@mui/material'
import {useMeetingContext} from '../meeting'
import {CopilotButton} from './CopliotButton'
import {ActionChip} from './ActionChip'
import {Link as RouterLink} from 'react-router-dom'
import {Routing} from '@zel-labs/routing'
import AssessmentIcon from '@mui/icons-material/Assessment'
import DeleteIcon from '@mui/icons-material/Delete'
import {useCallback} from 'react'
import {updateDoc} from 'firebase/firestore'
import {useNavigate} from 'react-router-dom'
import {typeConverter, useFirebaseApp} from '@progos/firebase-chat'
import type {Coaching} from '@zel-labs/shared/model'
import {useCoachingContext} from '../coaching'
import {Trans, useTranslation} from 'react-i18next'
import ReplayIcon from '@mui/icons-material/Replay'
import {getFunctions, httpsCallable} from 'firebase/functions'

export function MeetingActions() {
  const {meeting, data} = useMeetingContext()
  const navigate = useNavigate()
  const {role} = useCoachingContext()
  const {t} = useTranslation()

  const deleteMeeting = useCallback(() => {
    if (meeting != null) {
      const confirmed = window.confirm(t('meeting.actions.delete-meeting-confirmation'))
      if (confirmed) {
        updateDoc(meeting.ref, {status: 'delete'})
        const coachingRef = meeting.ref.parent.parent?.withConverter(typeConverter<Coaching>())
        if (coachingRef != null) {
          navigate(Routing.coaching(coachingRef))
        } else {
          navigate(Routing.home())
        }
      }
    }
  }, [meeting, navigate, t])

  const app = useFirebaseApp()

  const retryAnalysis = useCallback(
    async () => {
      if (meeting != null && meeting.data()?.retry_analysis !== true) {
        const retry_function = httpsCallable(getFunctions(app, 'europe-west1'), 'retry_analysis')
        await retry_function(meeting.ref.path)
      }
    }, [app, meeting])

  if (data == null || meeting == null) {
    return null
  }

  if (['transcribed', 'analyzed', null, undefined].includes(data.status)) {
    return <MeetingActionsContainer direction="row">
      <CopilotButton meeting={meeting}/>
      <Stack direction="row" flexGrow={1} justifyContent="flex-end" gap={1}>
        <ActionChip disabled={data.transcript == null} clickable size="small" variant="outlined"
                    component={RouterLink} to={Routing.lq(meeting.ref)}
                    icon={<AssessmentIcon color="secondary" fontSize="small"/>}
                    label={t('meeting.assessments.lq.title')}/>
        <ActionChip disabled={data.transcript == null} clickable size="small" variant="outlined"
                    component={RouterLink} to={Routing.disc(meeting.ref)}
                    icon={<AssessmentIcon color="info" fontSize="small"/>}
                    label={t('meeting.assessments.disc.title')}/>
      </Stack>
      {['coachee', 'coach'].includes(role) &&
        <IconButton size="small" onClick={deleteMeeting}><DeleteIcon/></IconButton>}
    </MeetingActionsContainer>
  }

  return <MeetingActionsContainer direction="row">
    <Stack direction="row" flexGrow={1} spacing={1} alignItems="center">
      <Typography variant="overline"><Trans i18nKey={`meeting.statuses.${data.status}`}/></Typography>
      <Typography variant="overline">
        {data.progress != null ? `${data.progress}%` : '...'}
      </Typography>
      {['coachee', 'coach', 'admin'].includes(role) && data.status === 'analysis_failed' && data.retry_analysis !== true &&
        <IconButton size="small" color="warning" onClick={retryAnalysis}><ReplayIcon/></IconButton>}
    </Stack>
    {['coachee', 'coach', 'admin'].includes(role) && data.progress == null && !['analyzing', 'preprocessing'].includes(data.status) &&
      <IconButton size="small" onClick={deleteMeeting}><DeleteIcon/></IconButton>}
  </MeetingActionsContainer>
}

export const MeetingActionsContainer = styled(Stack, {
  name: 'InterviewActions',
  slot: 'Root',
})(({theme}) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  justifyContent: 'stretch',
  alignItems: 'center',
}))

