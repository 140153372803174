import {
  Avatar,
  Box,
  Card,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader, IconButton,
  Link, Menu, MenuItem,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import React, {useCallback, useMemo, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {maxBy} from 'lodash'
import {useCoachingContext} from './CoachingContext'
import {Routing} from '@zel-labs/routing'
import {CopilotButton, UpdateCoachingForm, UploadButton} from '../components'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {CoacheeInviteForm} from '../components/CoachingForm'
import {updateDoc} from 'firebase/firestore'
import {Trans, useTranslation} from 'react-i18next'


export function CoachingCard() {
  const {coaching, meetings, role} = useCoachingContext()

  const lastMeeting = useMemo(() => meetings?.[0], [meetings])

  return (
    <Card variant="elevation" color="primary">
      <CoachingCardHeader/>
      <CardContent>
        <LastMeeting/>
      </CardContent>
      <CardActions>
        {<CopilotButton meeting={lastMeeting}/>}
      </CardActions>
    </Card>
  )
}

function CoachingCardHeader() {
  const {coaching, coachingTitle, coachingSubtitle} = useCoachingContext()
  if (coaching == null) {
    return null
  }
  const avatar = <Link component={RouterLink} to={Routing.coaching(coaching.ref)} sx={{textDecoration: 'inherit'}}>
    <PrimaryAvatar>{coachingTitle?.charAt(0)}</PrimaryAvatar>
  </Link>

  const headerTitle = (
    <Link component={RouterLink} to={Routing.coaching(coaching.ref)} sx={{textDecoration: 'inherit'}}>
      <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" variant="body1" component="span">
        {coachingTitle ?? <Skeleton/>}
      </Typography>
    </Link>
  )


  return <CardHeader avatar={avatar} title={headerTitle} subheader={coachingSubtitle} action={<CoachingMenu/>}/>
}

function CoachingMenu() {
  const {coaching, role} = useCoachingContext()

  const isDeleting = useMemo(() => ['delete', 'deleting'].includes(coaching?.data()?.status ?? 'n/a'), [coaching])
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }, [])

  const handleClose = useCallback(
    () => {
      setAnchorEl(null)
    }, [])


  const deleteCoaching = useCallback(() => {
    if (coaching != null) {
      const confirmed = window.confirm(t('coaching.coaching-card.delete-coaching-confirmation'))
      if (confirmed) {
        updateDoc(coaching.ref, {status: 'delete'}).then(handleClose)
      }
    }
  }, [coaching, handleClose, t])

  const [editDisplayed, setEditDisplayed] = useState(false)
  const [inviteDisplayed, setInviteDisplayed] = useState(false)
  const hideEdit = useCallback(() => {
    handleClose()
    setEditDisplayed(false)
  }, [handleClose])
  const hideInvite = useCallback(() => {
    handleClose()
    setInviteDisplayed(false)
  }, [handleClose])

  if ((role !== 'coach' && role !== 'coachee') || coaching == null) {
    return
  }

  return <>
    <IconButton onClick={handleClick}><MoreVertIcon/></IconButton>
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {role === 'coach' && <MenuItem dense={true} disabled={isDeleting} onClick={() => setEditDisplayed(true)}><Trans
        i18nKey="coaching.coaching-card.edit-coaching"/></MenuItem>}
      {role === 'coach' && <MenuItem dense={true} disabled={coaching.data()?.coachee.email == null || isDeleting}
                                     onClick={() => setInviteDisplayed(true)}><Trans
        i18nKey="coaching.coaching-card.invite-coachee"/></MenuItem>}
      {
        ['coachee', 'coach'].includes(role) &&
        <MenuItem dense={true} disabled={isDeleting} onClick={deleteCoaching}><Trans
          i18nKey="coaching.coaching-card.delete-coaching"/></MenuItem>
      }
    </Menu>
    <UpdateCoachingForm displayed={editDisplayed} hide={hideEdit}/>
    <CoacheeInviteForm displayed={inviteDisplayed} hide={hideInvite}/>
  </>
}

const CardHeader = styled(MuiCardHeader)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  '& .MuiCardHeader-content': {
    overflow: 'hidden',
  },
}))

function LastMeeting() {
  const {meetings, coaching} = useCoachingContext()
  const {t} = useTranslation()

  const lastMeeting = useMemo(
    () =>
      maxBy(meetings, (i) => i?.data()?.appointmentTime ?? i.data().uploadedAt),
    [meetings]
  )

  if (coaching == null) {
    return null
  }
  if (lastMeeting != null) {
    return <Link
      sx={{textDecoration: 'none'}}
      component={RouterLink}
      to={Routing.meeting(lastMeeting.ref)}
    >
      <Typography variant="caption" color="textSecondary" component="div">
        <Trans i18nKey="coaching.coaching-card.last-meeting"/>
      </Typography>
      <Typography variant="body2" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" component="div">
        {lastMeeting.data().title ?? lastMeeting.data().recordingFileName}
      </Typography>
      <Typography variant="caption" component="div" color="textSecondary" textAlign="right">
        {t('formats.short-date', {date: (lastMeeting.data().appointmentTime ?? lastMeeting.data().uploadedAt).toDate()})}
      </Typography>
    </Link>
  }


  return <Box>
    <Typography variant="caption" color="textSecondary" component="div">&nbsp;</Typography>
    <Typography variant="body2"
                textAlign="center" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis"
                component="div"><Link component={RouterLink} to={Routing.coaching(coaching.ref)}><Trans
      i18nKey="coaching.coaching-card.upload-meeting"/></Link></Typography>
    <Typography variant="caption" component="div" color="textSecondary" textAlign="right">&nbsp;</Typography>
  </Box>
}

const CardActions = styled(MuiCardActions)(({theme}) => ({
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

const CardContent = styled(MuiCardContent)(({theme}) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
}))


const PrimaryAvatar = styled(Avatar)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}))
