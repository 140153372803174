import {Stack, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { LeadershipQualityAssessment } from './types'
import InfoIcon from '@mui/icons-material/Info'
import * as React from 'react'


export function Summaries({ lqa }: { lqa: LeadershipQualityAssessment }) {
  return <Stack direction="column" spacing={4}>{
    lqa.output.summaries.map(
      (s, i) => {
        const spec = lqa.input.summaries.find(sp => sp.name===s.name)
        return <Stack direction="column" spacing={2} key={i}>
          <Tooltip
            title={<Typography variant="caption">{spec?.description ?? 'User or model fabricated summary'}</Typography>}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h3">{s.name}</Typography>
              <InfoIcon fontSize="small" color={spec!=null ? 'info':'warning'} />
            </Stack>
          </Tooltip>
          <TextContent variant="body1">{s.summary}</TextContent>
        </Stack>
      }
    )
  }</Stack>
}

const TextContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  textAlign: 'left'
}))
