import {useCallback, useMemo, useState} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  styled,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {typeConverter} from '@progos/firebase-chat'
import type {DocumentReference} from 'firebase/firestore'
import {
  addDoc,
  collection,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore'
import type {Meeting, Coaching} from '@zel-labs/shared/model'
import type {StorageReference, UploadTaskSnapshot} from 'firebase/storage'
import {getStorage, ref, uploadBytesResumable} from 'firebase/storage'
import {useCoachingContext} from '../coaching'
import {Trans} from 'react-i18next'
import {LanguageSelector} from './LanguageSelector'


interface UploadFormProps {
  displayed: boolean
  hide: () => void
}

export function UploadForm({displayed, hide,}: UploadFormProps) {
  const {coaching} = useCoachingContext()

  const [documentData, setDocumentData] =
    useState<Partial<DocumentData>>(emptyData)

  const {isUploading, uploadProgress, error, startUpload} = useUploader(
    documentData,
    coaching?.ref,
    hide
  )
  const fileTypes = useMemo(() => mimeTypes.join(','), [])

  return <Dialog open={displayed} maxWidth="sm" fullWidth onClose={hide}>
    <DialogTitle><Trans i18nKey="meeting.upload-form.title"/></DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              sx={{flexShrink: 0, flexGrow: 0}}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon/>}>
              <Trans i18nKey="meeting.upload-form.select-file"/>
              <VisuallyHiddenInput
                type="file"
                accept={fileTypes}
                onChange={(event) =>
                  setDocumentData((data) => ({
                    ...data,
                    file: event.target.files?.[0],
                  }))
                }
              />
            </Button>
            <Typography
              sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}
              variant="overline">
              {documentData.file?.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12}><LanguageSelector
          languageCode={documentData.language ?? 'hu-HU'}
          setLanguageCode={(language) => setDocumentData((data) => ({
            ...data, language
          }))}/>
        </Grid>
      </Grid>
      <DialogActions sx={{mt: 2}}>
        {isUploading ? <Box flexGrow={1}>
          {uploadProgress == null ? (
            <LinearProgress variant="indeterminate"/>
          ) : (
            <LinearProgress
              variant="determinate"
              value={uploadProgress * 100}
            />
          )}
        </Box> : <Box flexGrow={1} color="error.main">
          {error}
        </Box>}
        <Button disabled={documentData.file == null || isUploading}
                variant="contained" color="primary" onClick={startUpload}
        ><Trans i18nKey="meeting.upload-form.upload"/></Button>
        <Button disabled={isUploading} onClick={hide}><Trans i18nKey="meeting.upload-form.cancel"/></Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
}

interface DocumentData {
  file: File;
  language: string;
  appointmentTime: Timestamp;
}

const emptyData: Partial<DocumentData> = {
  language: 'hu-HU',
  appointmentTime: new Timestamp(Math.round(new Date().getTime() / 1000), 0),
}

function meetingUpload(
  meeting: DocumentReference<Meeting>,
  fileName: string
): string {
  const timestampedFileName = `${Date.now()}-${fileName}`
  const coaching = meeting.parent.parent?.withConverter(typeConverter<Coaching>())
  if (coaching == null) {
    throw new Error('Meeting has no parent coaching')
  }
  return `coachings/${coaching.id}/meetings/${meeting.id}/${timestampedFileName}`
}

function validateDocumentData(x: unknown): asserts x is DocumentData {
  if (typeof x !== 'object' || x === null) {
    throw new Error('Not an object')
  }

  const candidate = x as Record<string, unknown>

  const file = candidate['file']
  if (file == null || !(file instanceof File)) {
    throw new Error('No file')
  }

  const language = candidate['language']
  if (typeof language !== 'string') {
    throw new Error('No language')
  }


  const appointmentTime = candidate['appointmentTime']
  if (appointmentTime != null && !(appointmentTime instanceof Timestamp)) {
    throw new Error('Invalid appointment time')
  }
}

function useUploader(
  data: Partial<DocumentData>,
  coaching: DocumentReference<Coaching> | undefined,
  onSuccess: () => void
) {
  const [uploadProgress, setUploadProgress] = useState<number>()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isPaused, setIsPaused] = useState<boolean>(false)

  const startUpload = useCallback(async () => {
    if (data.file == null) {
      return
    }
    if (isUploading) {
      return
    }

    const handleUploadStateChange = () =>
      (snapshot: UploadTaskSnapshot) => {
        setUploadProgress(snapshot.bytesTransferred / snapshot.totalBytes)
        switch (snapshot.state) {
          case 'paused':
            setIsPaused(true)
            break
          case 'running':
            setIsUploading(true)
            break
          case 'success':
          case 'canceled':
          case 'error':
            setIsUploading(false)
            setIsPaused(false)
            setUploadProgress(undefined)
            break
        }
      }
    const handleUploadError =
      (meetingRef: DocumentReference<Meeting>) => (error: Error) => {
        setError(error.message)
        setIsUploading(false)
        setIsPaused(false)
        setUploadProgress(undefined)
        updateDoc(meetingRef, {progress: null, status: 'upload_failed'})
      }

    const handleUploadComplete =
      (meetingRef: DocumentReference<Meeting>, storageRef: StorageReference) => () => {
        setIsUploading(false)
        setIsPaused(false)
        setUploadProgress(undefined)
        setError(undefined)
        updateDoc(meetingRef, {
          status: 'uploaded',
          recording: storageRef.fullPath
        }).then(
          onSuccess
        )
      }

    try {
      validateDocumentData(data)
      const meetingReference = await createMeetingDoc(data, coaching)
      const uploadUrl = meetingUpload(meetingReference, data.file.name)
      const storage = getStorage()
      const storageRef = ref(storage, uploadUrl)
      setIsUploading(true)
      setError(undefined)
      setUploadProgress(0)
      setIsPaused(false)
      const uploadTask = uploadBytesResumable(storageRef, data.file)
      uploadTask.on(
        'state_changed',
        handleUploadStateChange(),
        handleUploadError(meetingReference),
        handleUploadComplete(meetingReference, storageRef)
      )
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError('An error occurred')
      }
      setIsUploading(false)
      setIsPaused(false)
      setUploadProgress(undefined)
    }
  }, [data, isUploading, onSuccess, coaching])

  return {isUploading, uploadProgress, error, isPaused, startUpload}
}

async function createMeetingDoc(
  data: DocumentData,
  coaching: DocumentReference<Coaching> | undefined
) {
  if (coaching == null) {
    throw new Error('No coaching')
  }
  const meetings = collection(coaching, 'meetings').withConverter(
    typeConverter<Meeting>()
  )
  return addDoc(meetings, {
    appointmentTime: data.appointmentTime ?? null,
    recordingFileName: data.file.name,
    languageCode: data.language,
    uploadedAt: serverTimestamp(),
    recording: null,
    transcript: null,
    status: 'uploading',
    progress: null,
    lead: null,
    meetingNotes: null,
    title: null,
    audio: null,
  })
}

const mimeTypes: string[] = [
  'text/plain', // Plain text files
  'application/pdf', // PDF documents
  'application/msword', // Microsoft Word .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word .docx

  'audio/mpeg', // MP3 audio
  'audio/aac', // AAC audio
  'audio/wav',
  'audio/x-wav', // WAV audio
  'audio/mp4',
  'audio/x-m4a', // M4A audio
  'audio/ogg', // OGG audio
  'audio/3gpp', // 3GPP audio, if it doesn't contain video
  'audio/amr', // AMR audio

  'video/x-msvideo',
  'video/mp4'
]

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})
