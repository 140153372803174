import {typeConverter, useFirestore, useFirestoreSnapshot} from '@progos/firebase-chat'
import {useMemo} from 'react'
import {doc} from 'firebase/firestore'
import type {Coaching} from '@zel-labs/shared/model'
import type {CoachingRole} from './CoachingContext'
import {useUserContext} from '@zel-labs/auth'

export function useCoaching(coachingId: string | undefined) {
  const firestore = useFirestore()
  const coachingReference = useMemo(
    () =>
      coachingId == null
        ? null
        : doc(firestore, 'coachings', coachingId).withConverter(
          typeConverter<Coaching>()
        ),
    [firestore, coachingId]
  )

  const coachingSnapshot = useFirestoreSnapshot(coachingReference)

  const {user, roles} = useUserContext()

  const role: CoachingRole | undefined = useMemo(() => {
    const data = coachingSnapshot?.data()
    if (data != null && user != null) {
      if (user.uid === data.coachee.uid) {
        return 'coachee'
      } else if (user.uid === data.coach.uid) {
        return 'coach'
      } else if (roles.includes('admin')) {
        return 'admin'
      }
    }
  }, [coachingSnapshot, roles, user])

  const  coachingTitle = useMemo(() => {
    if (coachingSnapshot != null && role != null) {
      const data = coachingSnapshot.data()
      if (data != null) {
        switch (role) {
          case 'coachee':
            return data.coach.name
          default:
            return data.coachee.name
        }
      }
    }
  }, [role, coachingSnapshot])



  return {coachingSnapshot, role, coachingTitle}
}
