import {
    Typography, Stack, Button, Tooltip
} from '@mui/material'
import * as React from 'react'
import {styled} from '@mui/material/styles'
import {
    AgreesWithReasoningControl,
    HallucinationControl,
    QualityRatingControl,
    useLQAFeedbackContext
} from './elements'
import {isEqual} from 'lodash'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar'
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar'
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar'
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorIcon from '@mui/icons-material/Error'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import PsychologyIcon from '@mui/icons-material/Psychology'
import type {QualityRating} from './types'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

export function LQAFeedbackForm() {
    const {
        actualFeedback
    } = useLQAFeedbackContext()

    return <Stack direction="column" spacing={2}>
        <AssessmentDisplay2/>
        {/*<AcceptOrReject />*/}

        {actualFeedback==null && <>
            <QualityRatingControl/>
            <AgreesWithReasoningControl/>
            <HallucinationControl/>
        </>
        }

        <Stack direction="row" spacing={2}
               justifyContent="flex-end" alignItems="center">
            <FeedbackActions/>
        </Stack>
    </Stack>
}

function FeedbackActions() {
    const {
        feedback, defaultFeedback, actualFeedback,
        submitFeedback, resetFeedback
    } = useLQAFeedbackContext()

    const acceptAssessment = React.useCallback(() => {
        submitFeedback(defaultFeedback)
    }, [defaultFeedback, submitFeedback])

    if (actualFeedback!=null) {
        return <Button variant="outlined" onClick={resetFeedback}>Reset</Button>
    }

    if (isEqual(feedback, defaultFeedback)) {
        return <Button variant="contained" color="success" onClick={acceptAssessment}>Accept</Button>
    }

    return <>
        <Button variant="outlined" onClick={resetFeedback}>Reset</Button>
        <Button variant="contained" onClick={() => submitFeedback()}>Submit</Button>
    </>
}

function AssessmentDisplay1() {
    const {
        spec, quality,
        assessment, actualFeedback
    } = useLQAFeedbackContext()

    if (assessment==null && actualFeedback==null) {
        return <Typography variant="h4">No assessment available</Typography>
    }


    return <Stack direction="column" spacing={2}>
        <Tooltip
                title={<Typography
                        variant="caption">{spec?.description ?? 'User or model fabricated quality'}</Typography>}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4">{quality}</Typography>
                <InfoIcon fontSize="small" color={spec!=null ? 'info':'warning'}/>
            </Stack>
        </Tooltip>

        <Stack direction="row" spacing={1} alignItems="center">
            <ConfidenceDisplay/>
            <RatingDisplay/>
        </Stack>
        <ReasoningDisplay/>
    </Stack>
}

function AssessmentDisplay2() {
    const {
        spec, quality,
        assessment, actualFeedback
    } = useLQAFeedbackContext()

    if (assessment==null && actualFeedback==null) {
        return <Typography variant="h4">No assessment available</Typography>
    }

    const rating = actualFeedback?.userRating ?? assessment?.rating ?? 'N/A'

    return <Stack direction="column" spacing={2}>
        <Tooltip
                title={<Typography
                        variant="caption">{spec?.description ?? 'User or model fabricated quality'}</Typography>}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4">{quality}</Typography>
                <InfoIcon fontSize="small" color={spec!=null ? 'info':'warning'}/>
            </Stack>
        </Tooltip>


        {actualFeedback==null ? <ModelRatingDisplay/>:<UserRatingDisplay/>}

        <ReasoningDisplay/>
    </Stack>
}

function RatingDisplay() {
    const {
        assessment,
        actualFeedback
    } = useLQAFeedbackContext()

    const rating = actualFeedback?.userRating ?? assessment?.rating ?? 'N/A'
    return <Typography variant="body1" lineHeight={1} fontWeight="bold">{rating}</Typography>
}
function ModelRatingDisplay() {
    const {
        assessment, actualFeedback
    } = useLQAFeedbackContext()

    if (assessment==null || actualFeedback!=null) {
        throw new Error('No assessment available')
    }

    const conficenceSign = assessment.confidence==='Mid' ? '?':
            assessment.confidence==='Low' ? '??':null
    const rating =
            assessment?.rating==null ? 'N/A'
                    :assessment.rating
    const ratingColor = assessment?.rating==='High'
            ? 'success':assessment?.rating==='Mid'
                    ? 'warning':assessment?.rating==='Low' ? 'error':undefined

    return <Stack direction="row" spacing={0} alignItems="center">
        <Typography variant="body1" lineHeight={1} fontWeight="bold">{rating}{conficenceSign!=null
                ? <Typography component="span" variant="overline" sx={{verticalAlign: 'super'}} lineHeight={1}
                              fontWeight="normal">(<b>{conficenceSign}</b>)</Typography>
                :null}
        </Typography>
    </Stack>
}

function UserRatingDisplay() {
    const {
        assessment, actualFeedback
    } = useLQAFeedbackContext()

    if (actualFeedback==null) {
        throw new Error('No assessment available')
    }


    let icon = null
    const modelRatingValue = assessment?.rating != null ? ratingValues[assessment.rating] : null
    const userRatingValue = actualFeedback.userRating != null ? ratingValues[actualFeedback.userRating]: null

    if (modelRatingValue == null) {
        if (userRatingValue == null) {
            icon = <RemoveCircleOutlineOutlinedIcon sx={{width: '1rem', height: '1rem'}} color="error"/>
        } else  {
            icon = <AccountCircleIcon sx={{width: '1rem', height: '1rem'}} color="info"/>
        }
    } else if (userRatingValue != null) {
        if (userRatingValue > modelRatingValue) {
            icon = <ArrowCircleUpIcon sx={{width: '1rem', height: '1rem'}} color="success"/>
        } else if (userRatingValue < modelRatingValue) {
            icon = <ArrowCircleDownIcon sx={{width: '1rem', height: '1rem'}} color="warning"/>
        } else {
            icon = <CheckCircleOutlineOutlinedIcon sx={{width: '1rem', height: '1rem'}}  color="info"/>
        }
    }

    const rating = actualFeedback.userRating ?? assessment?.rating ?? 'N/A'
    return <Stack direction="row" spacing={0} alignItems="center">
        <Typography variant="body1" lineHeight={1} fontWeight="bold">{rating}{icon!=null
                ? <Typography component="span" variant="overline" sx={{verticalAlign: 'super'}} lineHeight={1}
                              fontWeight="normal">{icon}</Typography>
                :null}</Typography>
    </Stack>
}

const ratingValues: Record<QualityRating, number | null> = {
    'Low': 1,
    'Mid': 2,
    'High': 3
}


function ConfidenceDisplay() {
    const {
        assessment,
        actualFeedback
    } = useLQAFeedbackContext()


    if (actualFeedback!=null) {
        if (actualFeedback.userRating===assessment?.rating) {
            return <CheckCircleIcon color="success"/>
        } else if (assessment!=null) {
            return <AccountCircleIcon color="warning"/>
        } else {
            return <AccountCircleIcon color="primary"/>
        }
    } else if (assessment?.confidence!=null) {
        if (assessment.confidence==='High') {
            return <SignalWifi4BarIcon color="success"/>
        } else if (assessment.confidence==='Mid') {
            return <NetworkWifi3BarIcon color="warning"/>
        } else if (assessment.confidence==='Low') {
            return <SignalWifi0BarIcon color="error"/>
        }
    } else {
        return null
    }

}

function ReasoningDisplay() {
    const {
        assessment,
        actualFeedback
    } = useLQAFeedbackContext()

    if (actualFeedback==null) {
        return <TextContent variant="body2">{assessment?.reasoning}</TextContent>
    } else {
        const agreeIcon = actualFeedback.agreesWithReasoning==='No'
                ? <CancelIcon color="error" fontSize="small"/>
                :actualFeedback.agreesWithReasoning==='OnlyInThisSituation'
                        ? <ErrorIcon color="warning" fontSize="small"/>
                        :null


        const hallucinationIcon =
                actualFeedback.reasoningPossiblyHallucinated==='Yes'
                        ? <BlurOnIcon color="warning" fontSize="small"/>
                        :actualFeedback.reasoningPossiblyHallucinated==='MayCaptureSentiment'
                                ? <PsychologyIcon color="info" fontSize="small"/>
                                :null

        const textDecoration =
                actualFeedback.agreesWithReasoning==='No' || actualFeedback.reasoningPossiblyHallucinated==='Yes'
                        ? 'line-through'
                        :'none'
        return <TextContent variant="body1" sx={{textDecoration}}><>
            {agreeIcon}{hallucinationIcon}
            {assessment?.reasoning}</>
        </TextContent>
    }

}

const TextContent = styled(Typography)(() => ({
    whiteSpace: 'pre-wrap',
    textAlign: 'left'
}))

