import type {Chat} from '@progos/firebase-chat'
import type {PropsWithChildren} from 'react'
import {useCallback} from 'react'
import {useMemo} from 'react'
import {typeConverter} from '@progos/firebase-chat'
import type {DocumentReference} from 'firebase/firestore'
import {addDoc, serverTimestamp} from 'firebase/firestore'
import {collection, orderBy, query, where} from 'firebase/firestore'
import type {Meeting} from '@zel-labs/shared/model'
import {ChatsContextProvider, useFirebaseAuth} from '@progos/firebase-chat'
import {useCoachingContext} from '../coaching'
import {useMeetingContext} from '../meeting'

export interface MeetingFocusedChat extends Chat {
  meetingRef: DocumentReference<Meeting>
  agent_id: 'meeting-focused'
}

export function MeetingCopilotContextProvider({children}: PropsWithChildren) {
  const {user} = useFirebaseAuth()
  const {coaching, role} = useCoachingContext()
  const {meeting, data} = useMeetingContext()

  const chatsCollection = useMemo(
    () =>
      coaching != null
        ? collection(coaching.ref, 'chats').withConverter(typeConverter<Chat>())
        : null,
    [coaching]
  )

  const chatsQuery = useMemo(
    () => {
      if (chatsCollection == null || meeting == null || user == null || role == null) {
        return null
      }

      const userFilter = role !== 'admin'
        ? query(chatsCollection, where('created_by', '==', user?.uid))
        : chatsCollection

      return query(userFilter,
        where('agent_id', '==', 'meeting-focused'),
        where('meetingRef', '==', meeting.ref),
        orderBy('updated_at', 'desc')
      ).withConverter(typeConverter<MeetingFocusedChat>())
    },
    [chatsCollection, meeting, role, user]
  )

  const createChat = useCallback(
    () => {
      if (meeting == null || user == null || chatsCollection == null || data?.transcript == null) {
        throw new Error('Chat not initialized')
      }
      return addDoc(chatsCollection.withConverter(typeConverter<MeetingFocusedChat>()), {
        agent_id: 'meeting-focused',
        meetingRef: meeting.ref,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        title: 'Meeting Chat',
        created_by: user.uid
      })
    }, [chatsCollection, data?.transcript, meeting, user]
  )

  if (chatsQuery == null) {
    return null
  }

  return <ChatsContextProvider createChat={createChat} chatsQuery={chatsQuery}>{children}</ChatsContextProvider>
}
