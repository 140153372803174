import {WelcomePage} from './WelcomePage'
import type {RouteObject} from 'react-router-dom'
import { useNavigate} from 'react-router-dom'
import {createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom'

import {
  AdminPage,
  CoachingPage,
  DashboardPage,
  MeetingCopilotPage,
  MeetingPage,
  RecordMeetingPage,
  UploadMeetingPage
} from '@zel-labs/coaching'
import {LqPage} from '@zel-labs/lq'
import React, {useEffect, useMemo} from 'react'
import {PaymentPage} from './PaymentPage'
import {DiscPage} from '@zel-labs/coaching'
import {SentryReportingErrorBoundary} from './SentryReportingErrorBoundary'
import {ProtectedRoute, useUserContext} from '@zel-labs/auth'
import {DashboardPageContent} from '../../../../libs/coaching/src/lib/DashboardPage'


function ErrorBoundaryLayout() {
  return <SentryReportingErrorBoundary>
    <Outlet/>
  </SentryReportingErrorBoundary>
}


const routes: RouteObject[] = [
  {
    element: <ErrorBoundaryLayout/>,
    children: [
      {index: true, element: <DashboardPage/>},
      {path: 'signUp', element: <SignUpPage/>},
      {path: 'admin', element: <AdminPage/>},
      {path: 'coaching/:coachingId', element: <CoachingPage/>},
      {path: 'coaching/:coachingId/upload', element: <UploadMeetingPage/>},
      {path: 'coaching/:coachingId/record', element: <RecordMeetingPage/>},
      {path: 'coaching/:coachingId/meetings/:meetingId', element: <MeetingPage/>},
      {path: 'coaching/:coachingId/meetings/:meetingId/copilot', element: <MeetingCopilotPage/>},
      {path: 'coaching/:coachingId/meetings/:meetingId/lq', element: <LqPage/>},
      {path: 'coaching/:coachingId/meetings/:meetingId/disc', element: <DiscPage/>}
    ]
  }
]

function SignUpPage() {
  const navigate = useNavigate()
  const {user} = useUserContext()
  useEffect(
    () => {
      if (user != null) {
        navigate('/')
      }
    }, [navigate, user]
  )
  return <ProtectedRoute authMethod="signUp">
    <DashboardPageContent/>
  </ProtectedRoute>
}

export function ZelLabsRouterProvider() {
  const router = useMemo(
    () =>
      createBrowserRouter(routes),
    []
  )
  return <RouterProvider router={router}/>
}


export default routes
