import type {Meeting, Coaching} from '@zel-labs/shared/model'
import type {DocumentReference} from 'firebase/firestore'
import {typeConverter} from '@progos/firebase-chat'

export function home(){
  return '/'
}

export function termsOfUse(){
  return `${root()}/MAX_ÁSZF_20240910.pdf`
}

export function privacyStatement(){
  return `${root()}/ZEL_adatkezelési tájékoztató_MAX_20240910.pdf`
}

export function root(){
  return ''
}

export function invitations(){
  return `${root()}/invitations`
}

export function coaching(reference: DocumentReference<Coaching>) {
  return `${root()}/coaching/${reference.id}`
}

export function uploadMeeting(reference: DocumentReference<Coaching>): string {
  return `${coaching(reference)}/upload`
}

export function recordMeeting(reference: DocumentReference<Coaching>): string {
  return `${coaching(reference)}/record`
}

export function meeting(reference: DocumentReference<Meeting>): string {
  const c = reference.parent.parent?.withConverter(typeConverter<Coaching>())
  if (c == null) {
    throw new Error('Meeting has no parent coaching')
  }

  return `${coaching(c)}/meetings/${reference.id}`
}

export function copilot(reference: DocumentReference<Meeting>): string {
  const p = reference.parent.parent?.withConverter(typeConverter<Coaching>())
  if (p == null) {
    throw new Error('Meeting has no parent coaching')
  }
  return `${meeting(reference)}/copilot`
}

export function lq(reference: DocumentReference<Meeting>): string {
  const p = reference.parent.parent?.withConverter(typeConverter<Coaching>())
  if (p == null) {
    throw new Error('Meeting has no parent coaching')
  }
  return `${meeting(reference)}/lq`
}

export function disc(reference: DocumentReference<Meeting>): string {
  const p = reference.parent.parent?.withConverter(typeConverter<Coaching>())
  if (p == null) {
    throw new Error('Meeting has no parent coaching')
  }
  return `${meeting(reference)}/disc`
}

