import type {
  LeadershipQualityAssessment,
  LQA, QualityFeedback,
  QualitySpec
} from '../types'
import type {
  Dispatch,
  PropsWithChildren,
  SetStateAction} from 'react'
import {
  createContext,
  useCallback,
  useContext, useEffect,
  useMemo,
  useState
} from 'react'

type LQAFeedbackContextType = {
  quality: string
  assessment: LQA | null
  spec: QualitySpec | null
  feedback: QualityFeedback
  defaultFeedback: QualityFeedback
  actualFeedback: QualityFeedback | null
  setFeedback: Dispatch<SetStateAction<QualityFeedback>>
  submitFeedback: (feedback?: QualityFeedback) => void
  resetFeedback: () => void
}

export const LQAFeedbackContext =
  createContext<LQAFeedbackContextType | undefined>(undefined)

export function LQAFeedbackContextProvider(
  props: PropsWithChildren<{
    lqa: LeadershipQualityAssessment,
    lqaFeedback: QualityFeedback[] | undefined,
    quality: string,
    updateFeedback: (feedback: QualityFeedback[]) => Promise<void>
  }>
) {
  const {
    lqa, quality,
    lqaFeedback, updateFeedback,
    children
  } = props

  const assessment = useMemo(
    () => lqa.output.qualities.find(q => q.quality===quality) ?? null,
    [lqa, quality]
  )

  const spec = useMemo(
    () => lqa.input.qualities.find(q => q.name===quality) ?? null,
    [lqa, quality]
  )

  const actualFeedback = useCallback(
    () => lqaFeedback?.find(f => f.quality===quality) ?? null,
    [lqaFeedback, quality]
  )


  const defaultFeedback = useCallback(
    (): QualityFeedback => {
      if (spec==null) {
        return {
          quality,
          excerptSufficientForAssessment: false,
          userRating: null,
          userReasoning: null,
          agreesWithReasoning: null,
          reasoningPossiblyHallucinated: null
        }
      }

      return {
        quality,
        excerptSufficientForAssessment: assessment?.rating!=null,
        userRating: assessment?.rating ?? null,
        userReasoning: null,
        agreesWithReasoning: assessment!=null ? 'Yes':null,
        reasoningPossiblyHallucinated: assessment!=null ? 'No':null
      }
    },
    [assessment, quality, spec]
  )

  const [feedback, setFeedback] =
    useState<QualityFeedback>(actualFeedback() ?? defaultFeedback)

  useEffect(() => {
    setFeedback(() => actualFeedback() ?? defaultFeedback())
  }, [actualFeedback, defaultFeedback])

  const submitFeedback = useCallback(
    (value?: QualityFeedback | null) => {
      const update = value===undefined ? feedback:value
      const currentUserFeedback = lqaFeedback?.filter(
        f => f.quality!==quality
      ) ?? []

      const userFeedback = update===null
        ? currentUserFeedback
        :[...currentUserFeedback, update]

      updateFeedback(userFeedback)
    }, [feedback, lqaFeedback, quality, updateFeedback])

  const resetFeedback = useCallback(
    () => submitFeedback(null),
    [submitFeedback]
  )

  if (feedback===undefined) {
    return null
  }

  const context: LQAFeedbackContextType = {
    quality, assessment, spec,
    feedback, actualFeedback: actualFeedback(), defaultFeedback: defaultFeedback(),
    setFeedback, submitFeedback, resetFeedback
  }


  return <LQAFeedbackContext.Provider value={context}>{children}</LQAFeedbackContext.Provider>
}

export function useLQAFeedbackContext() {
  const context = useContext(LQAFeedbackContext)
  if (context==null) {
    throw new Error('useLQAFeedbackContext must be used within LQAFeedbackContextProvider')
  }
  return context
}
