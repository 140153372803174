import { Typography } from '@mui/material'
import type { Document } from './types'
import { styled } from '@mui/material/styles'

export function Excerpt({ document }: { document: Document }) {
  return <TextContent>{document.content}</TextContent>
}

const TextContent = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  textAlign: 'left'
}))
