import React, {type ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Unstable_Grid2 as Grid,} from '@mui/material'
import {typeConverter, useFirestore} from '@progos/firebase-chat'
import type {CollectionReference, DocumentSnapshot, WithFieldValue} from 'firebase/firestore'
import {addDoc, collection, doc, serverTimestamp, setDoc, updateDoc} from 'firebase/firestore'
import type {CoacheeInvitation, Coaching, Invitation} from '@zel-labs/shared/model'
import {useUserContext} from '@zel-labs/auth'
import {useCoachingContext} from '../coaching'
import {Trans, useTranslation} from 'react-i18next'


interface CoachingFormProps {
  displayed: boolean
  hide: () => void
}


interface CoachingData {
  name: string
  email: string | null
}

interface FormError {
  coachName?: string
  coacheeEmail?: string
  coacheeName?: string
}

export function CreateCoachingForm({displayed, hide}: CoachingFormProps) {
  const [coachingData, setCoachingData] =
    React.useState<Partial<CoachingData>>({name: ''})
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const setCoachingField = useCallback(
    (field: keyof CoachingData) => (event: ChangeEvent<HTMLInputElement>) =>
      setCoachingData((data) => ({
        ...data,
        [field]: event.target.value
      })),
    []
  )

  const firestore = useFirestore()
  const {user} = useUserContext()
  const coachingsCollection = useMemo(
    () =>
      collection(firestore, 'coachings').withConverter(typeConverter<Coaching>()),
    [firestore]
  )

  const [error, setError] = useState<FormError | null>()

  const {t} = useTranslation()

  const submit = React.useCallback(
    () => {
      setError(null)
      if ((coachingData.name ?? '') !== '' && user != null) {
        setIsSubmitting(true)

        if (user.displayName == null) {
          throw new Error('Coach must have a display name')
        }
        if (user.email == null) {
          throw new Error('Coach must have an email')
        }
        if (coachingData.name == null) {
          setError({coacheeName: 'Coachee must have a name'})
          return
        }
        const coachingRef = doc(coachingsCollection)
        const payload = {
          coach: {
            name: user.displayName,
            email: user.email,
            uid: user.uid
          },
          coachee: {
            name: coachingData.name,
            email: coachingData.email ?? null,
            uid: null
          }
        }
        setDoc(coachingRef, payload).then(() => {
          setIsSubmitting(false)
          hide()
        })
      }
    },
    [coachingsCollection, hide, user, coachingData]
  )

  return <Dialog open={displayed} maxWidth="sm" fullWidth onClose={hide}>
    <DialogTitle><Trans i18nKey="coaching.coaching-form.new-coaching"/></DialogTitle>
    <DialogContent>
      <Grid container spacing={2} mt={1}>
        <Grid xs={12}>
          <TextField error={error?.coacheeName != null} size="small"
                     label={t('coaching.coaching-form.name')} fullWidth={true} autoFocus={true} value={coachingData.name ?? ''}
                     onChange={setCoachingField('name')}/>
        </Grid>
        <Grid xs={12}>
          <TextField size="small" label={t('coaching.coaching-form.email')} fullWidth={true} value={coachingData.email ?? ''}
                     onChange={setCoachingField('email')}/>
        </Grid>
      </Grid>
      <DialogActions sx={{mt: 2}}>
        <Button disabled={coachingData.name == null || isSubmitting} variant="contained" color="primary"
                onClick={submit}><Trans i18nKey="coaching.coaching-form.add"/></Button>
        <Button disabled={isSubmitting} onClick={hide}>
          <Trans i18nKey="coaching.coaching-form.cancel"/>
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
}

export function UpdateCoachingForm({displayed, hide}: CoachingFormProps) {
  const {coaching} = useCoachingContext()

  const data = useMemo(() => {
      const data = coaching?.data()
      if (data == null) {
        throw new Error('Coaching data is missing')
      }
      return data
    },
    [coaching]
  )

  const [coachingData, setCoachingData] =
    React.useState<Partial<CoachingData>>(data.coachee)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const {t} = useTranslation()
  const setCoachingField = useCallback(
    (field: keyof CoachingData) => (event: ChangeEvent<HTMLInputElement>) =>
      setCoachingData((data) => ({
        ...data,
        [field]: event.target.value
      })),
    []
  )

  const {user} = useUserContext()

  const submit = useCallback(
    () => {
      if ((coachingData.name ?? '') !== '' && user != null) {
        setIsSubmitting(true)
        if (coaching == null) {
          throw new Error('Coaching is missing')
        }
        if (user.displayName == null) {
          throw new Error('Coach must have a display name')
        }
        if (user.email == null) {
          throw new Error('Coach must have an email')
        }
        if (coachingData.name == null) {
          throw new Error('Coachee must have an name')
        }
        const coachingRef = coaching.ref
        const data = coaching.data()
        if (data == null) {
          throw new Error('Coaching data is missing')
        }
        const payload = {
          ...data,
          coachee: {
            ...data.coachee,
            name: coachingData.name ?? data.coachee.name,
            email: coachingData.email ?? data.coachee.email,
          }
        }

        updateDoc(coachingRef, payload).then(() => {
          setIsSubmitting(false)
          hide()
        })
      }
    },
    [coaching, coachingData.email, coachingData.name, hide, user]
  )

  if (coaching == null) {
    return null
  }

  return <Dialog open={displayed} maxWidth="sm" fullWidth onClose={hide}>
    <DialogTitle><Trans i18nKey="coaching.coaching-form.edit-coaching"/></DialogTitle>
    <DialogContent>
      <Grid container spacing={2} mt={1}>
        <Grid xs={12}>
          <TextField size="small" label={t('coaching.coaching-form.name')} fullWidth={true} autoFocus={true} value={coachingData.name ?? ''}
                     onChange={setCoachingField('name')}/>
        </Grid>
        <Grid xs={12}>
          <TextField size="small" label={t('coaching.coaching-form.email')} fullWidth={true} value={coachingData.email ?? ''}
                     onChange={setCoachingField('email')}/>
        </Grid>
      </Grid>
      <DialogActions sx={{mt: 2}}>
        <Button disabled={coachingData.name == null || isSubmitting} variant="contained" color="primary"
                onClick={submit}><Trans i18nKey="coaching.coaching-form.update"/></Button>
        <Button disabled={isSubmitting} onClick={hide}>
          <Trans i18nKey="coaching.coaching-form.cancel"/>
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
}


interface CoacheeInviteFormProps {
  displayed: boolean
  hide: () => void
}

export function CoacheeInviteForm({displayed, hide}: CoacheeInviteFormProps) {
  const {coaching} = useCoachingContext()
  const firestore = useFirestore()
  const invitationsCollection = useMemo(
    () => collection(firestore, 'invitations')
      .withConverter(typeConverter<Invitation>()),
    [firestore]
  )

  const {t} = useTranslation()

  const [state, setState] =
    React.useState<'idle' | 'sending' | 'sent' | 'failed'>('idle')

  const [message, setMessage] = React.useState<string>()

  useEffect(() => {
    setMessage(t('coaching.coaching-form.invitation-message', {
      coacheeName: coaching?.data()?.coachee.name,
      coachName: coaching?.data()?.coach.name
    }))
  }, [coaching, t])

  const payload = useMemo(
    () => {
      try {
        if (message != null) {
          return getPayload(coaching, message)
        }
      } catch (error) {
        // console.warn(error)
        return null
      }

    }, [coaching, message]
  )

  const sendInvitation = useCallback(
    async () => {
      if (state !== 'idle' || payload == null) {
        return
      }
      try {
        setState('sending')
        await createInvitation(payload, invitationsCollection)
        setState('sent')
        hide()
      } catch (error) {
        setState('idle')
      }
    },
    [state, payload, invitationsCollection, hide]
  )

  return <Dialog open={displayed} maxWidth="sm" fullWidth onClose={hide}>
    <DialogTitle><Trans i18nKey="coaching.coaching-form.invite-coachee"/></DialogTitle>
    <DialogContent>
      <Grid container spacing={2} mt={1}>
        <Grid xs={12}>
          <TextField inputProps={{style: {fontSize: 'small', lineHeight: 1}}}
                     size="small" label={t('coaching.coaching-form.invite-message')} fullWidth={true}
                     multiline={true} minRows={5} maxRows={10}
                     value={message}
                     onChange={(e) => setMessage(e.target.value)}/>
        </Grid>
      </Grid>
      <DialogActions sx={{mt: 2}}>
        <Button disabled={state !== 'idle' || payload == null} onClick={sendInvitation}><Trans i18nKey="coaching.coaching-form.invite"/></Button>
        <Button disabled={state === 'sending'} onClick={hide}>
          <Trans i18nKey="coaching.coaching-form.cancel"/>
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
}


function getPayload(
  coaching: DocumentSnapshot<Coaching> | null | undefined,
  message: string
) {
  if (coaching == null) {
    throw new Error('Coaching is missing')
  }
  const data = coaching.data()

  if (data == null) {
    throw new Error('Coaching data is missing')
  }
  const sender = data.coach.uid
  if (sender == null) {
    throw new Error('Coach must have an uid')
  }

  const coachee = data.coachee
  if (coachee == null) {
    throw new Error('Coachee is missing')
  }
  if (coachee.email == null) {
    throw new Error('Coachee must have an email')
  }
  const recipient = {
    name: coachee.name,
    email: coachee.email
  }

  if (recipient.email == null) {
    throw new Error('Coachee must have an email')
  }

  const payload: WithFieldValue<CoacheeInvitation> = {
    coaching: coaching.ref,
    message: message,
    role: 'coachee',
    recipient, sender,
    createdAt: serverTimestamp(),
  }

  return payload
}

async function createInvitation(payload: WithFieldValue<Invitation>, collection: CollectionReference<Invitation>) {
  await addDoc(collection.withConverter(typeConverter<CoacheeInvitation>()), payload)
}
